import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~4],
		"/(protected)/account": [~10],
		"/(protected)/admin": [~11,[2]],
		"/(protected)/admin/players": [~12,[2]],
		"/(protected)/admin/players/[id]": [~13,[2]],
		"/(protected)/admin/plots": [~14,[2]],
		"/(protected)/admin/plots/[id]": [~15,[2]],
		"/(protected)/admin/regions": [~16,[2]],
		"/(protected)/admin/regions/[id]": [~17,[2]],
		"/(protected)/admin/servers": [~18,[2]],
		"/(protected)/admin/servers/create": [~20,[2]],
		"/(protected)/admin/servers/[id]": [~19,[2]],
		"/(protected)/admin/tiles": [~21,[2]],
		"/(protected)/admin/tiles/[id]": [~22,[2]],
		"/(protected)/admin/worlds": [~23,[2]],
		"/(protected)/admin/worlds/create": [~25,[2]],
		"/(protected)/admin/worlds/[id]": [~24,[2]],
		"/(auth)/auth": [5],
		"/(auth)/callback": [6],
		"/(auth)/forgot-password": [~7],
		"/(protected)/forum": [26],
		"/(protected)/game": [~27,[3]],
		"/(protected)/game/getting-started": [~28,[3]],
		"/(protected)/game/map": [~29,[3]],
		"/(protected)/game/settlements": [~30,[3]],
		"/(protected)/game/settlements/[id]": [~31,[3]],
		"/introduction": [32],
		"/kb": [33],
		"/(auth)/register": [~8],
		"/(auth)/sign-in": [~9],
		"/support": [34]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';